<template>
  <div class="dailyUseTable">
    <div class="top_opera">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
        <el-form-item label="监控时间">
          <el-date-picker
            v-model="formInline.queryTime"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="统计省份">
          <el-select v-model="formInline.regionId" placeholder="请选择省份" filterable clearable>
            <el-option
              v-for="item in provinceData"
              :key="item.provinceId"
              :label="item.provinceName"
              :value="item.provinceId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校">
          <el-select v-model="formInline.tenantId" placeholder="请选择学校" filterable clearable>
            <el-option
              v-for="item in allSchoolData"
              :key="item.tenantId"
              :label="item.tenantName"
              :value="item.tenantId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校编码">
          <el-input v-model="formInline.encoding" placeholder="学校编码" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="btn_area">
        <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
        <el-button type="info" plain @click="resetEvent">重置</el-button>
      </div>
    </div>
    <div class="table_sList">
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="540"
        align="center"
        @sort-change="sortEvent">
        <el-table-column
          label="序号"
          align="center"
          width="55"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.schoolName != '总计'">{{
              (formInline.page - 1) * formInline.pageSize + scope.$index + (formInline.page == 1 ? 0 : 1)
            }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columnList"
          :key="index"
          :prop="item.prop"
          :label="item.label ? item.label : '/'"
          :width="item.width"
          align="center"
          :sortable="item.prop == 'count' ? 'custom' : false"
        >
          <template slot-scope="scope">
            <span v-if="scope.row[scope.column.property] == null">/</span>
            <span v-else>
              {{ scope.row[scope.column.property] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'dailyUseTable',
  components: {
    Pagination
  },
  data() {
    return {
      formInline: {
        page: 1,
        pageSize: 10,
        encoding: null,
        regionId: null,
        orderFlag: 1,
        queryTime: null,
        tenantId: null
      },
      provinceData: [],
      allSchoolData: [],
      columnList: [
        {
          prop: "schoolName",
          label: "学校名称"
        },
        {
          prop: "encoding",
          label: "学校编码"
        },
        {
          prop: "count",
          label: "累计使用人数"
        }
      ],
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 0
      },
    }
  },
  methods: {
    showEvent() {
      this.getNowTime()
      this.getTenantDown()
      this.getProviceEvent()
      this.getPlatformTableData()
    },
    // 获取昨天时间00：00：00
    getNowTime() {
      this.formInline.queryTime = new Date().setHours(0, 0, 0, 0) - 86400000
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
        }
      })
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getProvice({}, res => {
        if(res.code == 0) {
          this.provinceData = res.data;
        }
      })
    },
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getPlatformTableData();
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.getNowTime()
    },
    // 表格数据
    getPlatformTableData() {
      this.$request.platformDeilyUseTableDetails(this.formInline).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data.page;
          this.tableData = records;
          if(current == 1) this.tableData.unshift(res.data.data.total)
          console.log(this.tableData,985211)
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getPlatformTableData();
    },
    // 排序
    sortEvent(column) {
      this.formInline.orderFlag = column.order == "descending" ? 1 : 2
      this.getPlatformTableData()
    }
  },
}
</script>

<style lang='less' scoped>
  .dailyUseTable {
    box-sizing: border-box;
    padding: 20px;
    .top_opera {
      display: flex;
      .el-form {
        flex: 4;
      }
      .btn_area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 0!important;
    }
  }
</style>